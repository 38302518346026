.e_go-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 1rem;
    background-color: #000;
    z-index: 9;
    overflow-y: scroll;
}

.hide {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s ease-in-out;
}

.show {
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.4s ease-in-out;
}

.close-button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.close-button-container img {
    margin-right: 4rem;
    cursor: pointer;
    transform: scale(1.3);
}

.e_go-container .video-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.e_go-container .video-container .video-player {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 185px !important;
}

.e_go-container .video-container iframe {
    width: 100% !important;
    height: auto !important;
}

.e_go-container .copy-content {
    display: flex;
    flex-direction: column;
    width: 90%;

    .title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .description-container {
        width: 90%;
    }
}

.media-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
    width: 90%;
    margin-top: 7rem;
    margin-bottom: 5rem;
}

.media-container .image {
    max-width: 100%;
    height: auto;
}

@media only screen and (min-width: 769px) and (max-width: 1920px) {
    .e_go-container .video-container {
        width: 100vw;
    }
    
    .e_go-container .video-container .video-player {
        width: 100% !important;
        margin-top: 10rem;
        margin-bottom: 4rem;
    }
    
    .e_go-container .video-container .video-player div {
        height: 400px !important;
    }

    .e_go-container .video-container .video-player iframe {
        width: 100% !important;
        height: 100% !important
    }
}