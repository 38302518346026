.loader {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1000;
    backdrop-filter: blur(50px);
    display: flex;
    align-items: center;
    justify-content: space-around;
    top: 0; 
    flex-direction: column;
}

.loader .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0;
    margin-top: 15rem;
}

.loader .logo svg {
    width: 50%;
    transition: animation 0.5s ease-in-out;
}

.loader .logo #part-one-animate {
    animation: drawPath 5s linear forwards;
}

.loader .logo #part-two-animate {
    animation: drawPath 5s linear forwards;
}

.loader .credits-container {
    margin-top: 6rem;
}

.loader .credits-container h1 {
    font-family: 'Gian';
    font-size: small;
    padding: 0 100px;
    opacity: 0.5;
    text-align: center;
    line-height: 25px;
    opacity: 0;
    animation: animateCredits 5s linear forwards;
}

.loader .credits-container h1 > a {
    color: #fff;
    text-decoration: none;
}

@keyframes animateCredits {
    to {
        opacity: 0.5
    }
}
  
@keyframes drawPath {
    to {
      stroke-width: 50;
      stroke-dasharray: 0;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1920px) {
    .loader {
        justify-content: space-evenly;
    }
    .loader .logo {
        transform: scale(0.5);
    }
}