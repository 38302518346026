.email-popup-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 999;
    backdrop-filter: blur(30px);
    display: flex; 
    align-items: center;
    justify-content: center;
    transition: backdrop-filter 1s ease-in-out;
}

.email-popup-container.hide {
    backdrop-filter: blur(0px);
    pointer-events: none;
}

.email-popup {
    display: flex;
    flex-direction: column;
    background-color: #000;
    border: 1px solid #515151;
    padding: 3rem;
    max-width: 65vw;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 1s ease-in-out;
    padding-bottom: 2rem;
}

.email-popup.hide {
    opacity: 0;
    pointer-events: none;
}

.email-popup .email-close-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    img {
        margin-right: 0;
        cursor: pointer;
        transform: scale(1);
    }
}

.email-popup .copy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.email-popup .copy h1 {
    font-weight: lighter;
    margin-bottom: 0;
}

.email-popup .copy p {
    margin-top: 0.5rem;
    opacity: 0.8;
    font-family: 'Gian';
    font-size: small;
    text-transform: uppercase;
}

.email-popup .form-container {
    width: 100%;
    margin-bottom: 1rem;
}

.email-popup .form-container form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.email-popup .form-container form input[type=text] {
    background-color: #515151;
    border: none;
    height: 30px;
    flex-shrink: 0; 
    font-size: 1em;
    color: #fff;
    &:focus {
        outline: none;
    }
}

.email-popup .form-container form .submit {
    background-color: #000;
    border: 1px solid #fff;
    text-transform: uppercase;
    color: #fff;
    font-family: 'Gian';
    opacity: 0.81;
    padding: 0.5rem;
    cursor: pointer;
}


@media only screen and (min-width: 769px) and (max-width: 1920px) {
    .email-popup {
        max-width: 20vw;
    }
}