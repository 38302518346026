.landing-column {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: scale(1);
}

.landing-column h1 {
    font-family: 'Gian', sans-serif;
    font-size: 32px;
    text-transform: uppercase;
    margin-bottom: 0;
    position: relative;
}

.landing-column p {
    font-family: Helvetica, sans-serif;
    text-transform: uppercase;
    margin-top: 0;
}

.null, .infinite, .e_go {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.segmentation-block {
    max-height: 200px;
    max-width: 1px;
    height: 100px;
    width: 1px;
    border-left: dashed 2px #fff;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.e_go.portal, .infinite.portal, .null.portal {
    cursor: pointer;
}

.e_go.portal h1, .e_go.portal p,
.infinite.portal h1, .infinite.portal p,
.null.portal h1, .null.portal p {
    position: relative;
    z-index: 1;
}

.e_go.portal .video-bg-container,
.infinite.portal .video-bg-container,
.null.portal .video-bg-container {
    position: absolute;
    transform: scale(0.16);
    translate: 0 -44%;
}

.e_go.portal .video-bg-container {
    translate: 0 -45%;
}