@font-face {
    font-family: 'Gian';
    src: url(../src/Assets/Giane-Light.otf);
}

body, html {
    padding: 0;
    margin: 0;
    background-color: #000;
    height: auto;
    width: 100vw;
    padding-top: 2rem;
}

h1, p {
    color: #fff;
    mix-blend-mode: difference;
}

h1 {
    font-family: 'Gian';
    text-transform: uppercase;
    color: #FFF;
    font-family: Giane;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 1.336px;
    text-transform: uppercase;
}

p {
    font-family: 'Helvetica';
    font-weight: lighter;
    opacity: 0.85;
}

@media only screen and (min-width: 769px) and (max-width: 1920px) {
    body, html {
        height: 100%;
    }
}